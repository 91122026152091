import React from "react"
import { navigate } from "gatsby"
import { handleLogin } from "../../services/auth"
import FormButton from "../../components/buttons/form_button"

class Login extends React.Component {
  handleSubmit = () => handleLogin((user) => navigate(`/asset-library/`))
  render() {
    return (
      <FormButton onClick={this.handleSubmit} isLogin={false} label="Login" />
    )
  }
}

export default Login
