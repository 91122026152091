import React from "react"

import styles from "./index.module.scss"

interface FormButtonProps {
  isLogin?: boolean
  label: string
  onClick?: () => void
}
const FormButton = ({ label, isLogin, onClick }: FormButtonProps) => {
  return isLogin ? (
    <button type="submit" className={`${styles.button} ${styles.buttonLogin}`}>
      {label}
    </button>
  ) : (
    <button
      type="button"
      className={`${styles.button} ${styles.buttonLogin}`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

export default FormButton
